@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Lexend";
  src: url("/public/fonts/lexend.ttf");
}

body {
  background-color: #0c0c0c; /* Black */
  color: #f7f7f7; /* White */
}

.pulse-button {
  border-radius: 9999px; /* Large value to ensure fully rounded corners */
  background-color: #ffffff; /* Example button color, feel free to change */
  color: rgb(12, 12, 12);
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  animation: pulse 2s infinite; /* Apply the pulse animation */
}

.pulse-loader {
  border-radius: 9999px; /* Large value to ensure fully rounded corners */
  background-color: #ffffff; /* Example button color, feel free to change */
  color: rgb(12, 12, 12);
  border: none;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.3s; /* Smooth transition for hover effect */
  animation: pulse-sm 2s infinite; /* Apply the pulse animation */
}


.pulse-button:hover {
  background-color: #0056b3; /* Darker shade for hover effect, adjust as needed */
}

@keyframes pulse {
  0% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(1.1); /* Scales up to 110% */
  }
  100% {
    transform: scale(1); /* Returns to original size */
  }
}


@keyframes pulse-sm {
  0% {
    transform: scale(1); /* Original size */
  }
  50% {
    transform: scale(2); /* Scales up to 110% */
  }
  100% {
    transform: scale(1); /* Returns to original size */
  }
}
